<template>

    <div class="backdrop">
      <div class="b-modal">
        <div class="modal-header text-right">
          <div class="modal-title">
            <h4 class="text-uppercase text-white">Are you sure?</h4>
          </div>
          
        </div>
        <div class="p-5">
          <div class="mx-auto">
            
            <h6 class="pt-2">You are leaving this page.</h6>
            <h6>You will lose your data once you leave this page.</h6>
  
            <div class="d-flex justify-content-center mt-4 mb-4">
                <button class="btn btn-primary text-uppercase mr-sm-2" @click="closeModal">Stay</button>

                <button class="btn btn-secondary text-uppercase mr-sm-2" @click="goHome">Leave</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
     export default {
          name: 'Exit',
          data(){
              return {

              }
          },
          methods:{
            closeModal() {
                this.$emit("close");
            },
            goHome(){
                 this.$router.push({name: 'Dashboard', params: { userId: this.$route.params.userId}})
                
                  window.location.reload();
            }
          }

      }

  </script>
  <style scoped>
  .b-modal{
   width: 620px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    overflow-x: hidden !important;
    color:black;
  }
.modal-header{
    width: 100%;
   background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
  }
.modal-title{
    margin: 0 auto;
    justify-content: center;
  }
.modal-title img{
    width: 4rem;
  }
  
.backdrop{
     top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);
  }
.b-modal h4{
    padding: 15px;
  }
  .btn{
    margin-right: 10px;
  }

  @media only screen and (max-device-width: 580px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
       .b-modal{
        width: 300px;
        height: auto;
        margin: 175px auto;
        background: white;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
      }
       
    }
  </style>